<template>
  <v-combobox
    v-model="selectedList"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :multiple="multiple"
    :disabled="role != 'superadmin'"
    :label="label"
    deletable-chips
    persistent-hint
    small-chips
    @change="$emit('update:integration', selectedList)"
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
// import gql from 'graphql-tag'

export default {
  name: 'SelectIntegration',
  props: {
    integration: {
      required: false,
      type: [String, Array],
      default: null,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: 'Integration',
    },
  },
  // apollo: {
  //   organizations: {
  //     query: gql`
  //       query {
  //         organizations {
  //           id
  //           name
  //         }
  //       }
  //     `,
  //     result(result) {
  //       this.items = JSON.parse(JSON.stringify(result.data.organizations)).map(org => org.name)
  //     },
  //   },
  // },
  setup(props) {
    const items = ref(['ELEN', 'HT'])
    const selectedList = ref(null)
    const search = ref(null)
    const role = localStorage.getItem('userAbility')

    watch(
      () => props.integration,
      () => {
        selectedList.value = props.integration
      },
    )

    return {
      items,
      selectedList,
      search,
      role,
    }
  },

  // mounted() {
  //   this.$apollo.queries.organizations.refetch()
  // },
}
</script>
