<template>
  <div>
    <loading v-if="$apollo.loading" />
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              @click="changeOrder('NAME')"
              class="text-uppercase"
            >
              Name
              <sorting-arrows />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('ID_IMEI')"
            >
              IMEI
              <sorting-arrows />
            </th>
            <th
              @click="changeOrder('DESCRIPTIVE_LOCATION')"
              class="text-center text-uppercase"
            >
              Location
              <sorting-arrows />
            </th>
            <th
              class="text-center text-uppercase"
              @click="changeOrder('ORGANIZATION_NAME')"
            >
              Organization
            </th>
            <th
              @click="changeOrder('BATTERY')"
              class="text-center text-uppercase" >
              Battery
            </th>
            <th
              @click="changeOrder('STATUS')"
              class="text-center text-uppercase">
              Status
              <sorting-arrows />
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allDevices && allDevices.length == 0">
            <div class="text-center my-5">
              <h3>
                No items
              </h3>
            </div>
          </tr>
          <tr
            v-else
            v-for="item in allDevices"
            :key="item.id"
          >
            <td>
              <div v-if="item.name">
                {{ item.name }}
              </div>
               <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              {{ item.id_imei }}
            </td>
            <td class="text-center">
              <div v-if="item.location">
                <v-btn
                  small
                  plain
                  @click="openGoogleMap(item.location)"
                >
                  {{ item.location }}
                </v-btn>
                <br> {{ item.descriptive_location ? item.descriptive_location : '' }}
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.organizations.length > 0">
                {{ getOrganizations(item.organizations) }}
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.battery">
                <v-badge
                  :color="getBatteryColor(item.battery)"
                  inline
                  dot
                >
                </v-badge>
                {{ item.battery }} %
              </div>
              <div v-else>
                /
              </div>
            </td>
            <td class="text-center">
              <v-chip
                :color="getStatusColor(item)"
                small
                class="font-weight-semibold text-capitalize"
              >
                {{ item.status }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-btn
                :disabled="item.status == 'unclaimed' && role == 'admin'"
                icon
                small
                @click="$router.push('sensors/' + item.id)"
              >
                <v-icon size="20">
                  {{ icons.mdiViewListOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="() => {
                  selectedDevice = item
                  showModal = true
                }"
              >
                <v-icon size="20">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                v-if="role == 'superadmin'"
                icon
                small
                @click="() => {
                  selectedDevice = item
                  showDeleteModal = true
                }"
              >
                <v-icon size="20">
                  {{ icons.mdiTrashCanOutline  }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="devices"
      :count="countPages"
      :page.sync="currentPage"
    />
    <device-dialog-form
      v-if="showModal"
      @success="getDevices()"
      :show.sync="showModal"
      :device.sync="selectedDevice"
      :role="role"
    />
    <delete-device-dialog
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      title="Delete Device"
      @success="getDevices()"
      :device.sync="selectedDevice"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiPencilOutline, mdiTrashCanOutline, mdiViewListOutline, mdiArrowUpDownBold } from '@mdi/js'
// import axios from '@axios'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import Loading from '../Loading.vue'
import DeviceDialogForm from './DeviceDialogForm.vue'
import DeleteDeviceDialog from './DeleteDeviceDialog.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'DevicesTable',
  components: {
    Pagination,
    Loading,
    DeviceDialogForm,
    DeleteDeviceDialog,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    devices: {
      query: gql`
        query ($page: Int!, $search: String, $orderBy: [QueryDevicesOrderByOrderByClause!]) {
          devices(page: $page, search: $search, orderBy: $orderBy) {
            data {
              id
              name
              id_imei
              status
              location
              descriptive_location
              battery
              maintenance
              integration

              charging_station {
                id
                name
                distance
                charging_point_id
              }

              sensors {
                id
                name
                last_value
              }

              organizations {
                id
                name
              }
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          orderBy: [
            {
              column: this.column ?? 'ID_IMEI',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
        }
      },
      result(result) {
        this.allDevices = result.data.devices.data
        this.countPages = result.data.devices.paginatorInfo.lastPage
        this.currentPage = result.data.devices.paginatorInfo.currentPage
      },
    },
  },
  setup(props) {
    const role = localStorage.getItem('userAbility')
    const currentPage = ref(1)
    const countPages = ref(1)
    const searchProp = ref(props.search)
    const showModal = ref(false)
    const showDeleteModal = ref(false)
    const column = ref('NAME')
    const order = ref('ASC')
    const allDevices = ref([])
    const selectedDevice = ref(null)

    const getBatteryColor = number => {
      if (number >= 30) return 'success'
      if (number < 30 && number >= 10) return 'warning'

      return 'error'
    }

    return {
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiViewListOutline,
        mdiArrowUpDownBold,
      },
      role,
      allDevices,
      currentPage,
      countPages,
      searchProp,
      showModal,
      showDeleteModal,
      column,
      order,
      getBatteryColor,
      selectedDevice,
    }
  },

  methods: {
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
    openGoogleMap(location) {
      const gps = location.split(', ')
      const lat = gps[0].split(' ')[1]
      const long = gps[1].split(' ')[1]
      const urlSuffix = [lat, long]

      // eslint-disable-next-line
      window.open('https://www.google.com/maps/search/?api=1&query=' + urlSuffix, '_blank')
    },
    getOrganizations(organizations) {
      return organizations.map(org => org.name).toString()
    },
    getDevices() {
      this.$apollo.queries.devices.refetch()
    },
    getStatusColor(device) {
      if (device.status === 'online' || (device.status === 'online' && device.maintenance)) return 'success'
      if (device.status === 'offline') return 'error'
      if (device.status === 'offline' && device.maintenance) return 'secondary'
      if (device.status === 'ready for setup') return 'warning'

      return ''
    },
  },
}
</script>
