<template>
  <v-autocomplete
    v-model="selectedList"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :item-text="getText"
    :multiple="multiple"
    :label="label"
    small-chips
    deletable-chips
    return-object
    no-filter
    v-bind="$attrs"
    v-on="$listeners"
    @change="$emit('update:chargingStation', selectedList)"
  ></v-autocomplete>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import gql from 'graphql-tag'

export default {
  name: 'SelectChargingStation',
  props: {
    chargingStation: {
      required: false,
      type: Object,
      default: null,
    },
    device_id: {
      required: false,
      type: String,
      default: null,
    },
    integration: {
      required: false,
      type: String,
      default: null,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: 'Charging Point',
    },
  },
  apollo: {
    charging_stations: {
      query: gql`
        query ($search: String, $integration: String, $device_id: ID) {
          charging_stations(search: $search, integration: $integration, device_id: $device_id) {
            data {
              id
              name
              charging_point_id
              distance
            }
          }
        }
      `,
      skip() {
        return !this.integration
      },
      variables() {
        return {
          integration: this.integration,
          device_id: this.device_id,
          search: this.search,
        }
      },
      debounce: 350,
      result(result) {
        this.items = JSON.parse(JSON.stringify(result.data.charging_stations.data))
      },
    },
  },
  setup(props) {
    const items = ref([])
    const selectedList = ref(null)
    const search = ref(null)

    watch(
      () => props.chargingStation,
      () => {
        selectedList.value = props.chargingStation
      },
    )

    return {
      items,
      selectedList,
      search,
    }
  },

  watch: {
    selectedList(newVal, oldVal) {
      if (newVal === null && oldVal !== null && this.search !== null) this.search = null
    },
  },

  mounted() {
    this.$apollo.queries.charging_stations.refetch()
  },

  methods: {
    getText(item) {
      return `${item.charging_point_id} - ${item.name} - ${item.distance?.toFixed(2)} km`
    },
  },
}
</script>
