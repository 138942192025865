<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ 'Edit device: ' + device.id_imei }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="usersRole === 'superadmin'">
            <v-col cols="12">
              <select-organization :multiple="true" :organization.sync="organizations"></select-organization>
            </v-col>
            <v-col cols="12">
              <select-integration :integration.sync="integration"></select-integration>
            </v-col>
          </v-row>
          <v-row v-if="device.status !== 'unclaimed'">
            <v-col cols="12">
              <v-text-field
                dense
                label="Name"
                hide-details="auto"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="Status"
                disabled
                hide-details="auto"
                v-model="device.status"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="Location"
                disabled
                hide-details="auto"
                v-model="device.location"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="Descriptive location"
                hide-details="auto"
                v-model="descriptiveLocation"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <select-charging-station
                :device_id="device ? device.id : null"
                :integration="integration"
                :disabled="!device.location || !integration"
                :chargingStation.sync="chargingStation"
              >
              </select-charging-station>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="maintenance"
                hide-details
              >
               <template v-slot:label>
                 <span>
                   Maintenance mode
                   <br>
                   <small class="text--disabled text-capitalize">Enabling turns off all alert notifications</small>
                 </span>
              </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="!isValid"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import gql from 'graphql-tag'
import SelectOrganization from '../SelectOrganization.vue'
import SelectIntegration from '../SelectIntegration.vue'
import SelectChargingStation from '../SelectChargingStation.vue'

export default {
  components: { SelectOrganization, SelectIntegration, SelectChargingStation },
  name: 'DeviceDialogForm',
  props: {
    device: {
      type: Object,
      required: false,
      default: null,
    },
    role: {
      type: String,
      required: true,
      default: 'device',
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const isDialogVisible = ref(props.show)
    const name = ref(null)
    const descriptiveLocation = ref(null)
    const organizations = ref([])
    const maintenance = ref(false)
    const integration = ref(null)
    const chargingStation = ref(null)
    const errorMessages = ref([])
    const usersRole = ref(props.role)
    const vm = getCurrentInstance().proxy

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:show', false)
      emit('update:device', null)
    }

    const getOrganizationName = deviceOrganizations => {
      const names = []

      deviceOrganizations.forEach(org => {
        names.push(org.name)
      })

      return names
    }

    onMounted(() => {
      if (props.device !== null) {
        descriptiveLocation.value = props.device.descriptive_location
        organizations.value = getOrganizationName(props.device.organizations)
        name.value = props.device.name
        integration.value = props.device.integration
        maintenance.value = props.device.maintenance ?? false
        chargingStation.value = props.device.charging_station ?? null
      }
    })

    const save = async () => {
      try {
        await root.$apollo.mutate({
          mutation: gql`
            mutation ($device: DeviceInput!) {
              updateDevice(device: $device) {
                id
              }
            }
          `,
          variables: {
            device: {
              id: props.device.id,
              name: name.value,
              descriptive_location: descriptiveLocation.value,
              organizations: organizations.value,
              maintenance: maintenance.value,
              integration: integration.value,
              charging_station_id: chargingStation.value?.id,
            },
          },
        })
        emit('success')
        vm.$toastr.s('Device successfully updated!')
      } catch (err) {
        console.log(err)
        // TODO: errors
        vm.$toastr.e('Failed!')
      } finally {
        emit('update:device', null)
        emit('update:show', false)
        isDialogVisible.value = false
      }
    }

    /* eslint-disable */
    const isValid = computed(() => {
      if (organizations.value.length > 0 && integration.value !== null) {
        return true
      }

      return false
    })

    return {
      isDialogVisible,
      usersRole,
      descriptiveLocation,
      name,
      organizations,
      closeModal,
      save,
      validators: {
        required,
        emailValidator,
      },
      errorMessages,
      isValid,
      maintenance,
      integration,
      chargingStation,
    }
  },

  watch: {
    integration(newVal, oldVal) {
      if (newVal === null && oldVal !== null) this.chargingStation = null
    },
  },
}
</script>
